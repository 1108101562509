/*
 *
 * CheckoutPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const goToStep = (data: any) => action(ActionTypes.GO_TO_STEP, data);
export const setCheckout = (data: any) => action(ActionTypes.SET_CHECKOUT, data);
export const setCheckoutStatus = (data: any) => action(ActionTypes.SET_CHECKOUT_STATUS, data);
export const checkoutCart = () => action(ActionTypes.CHECKOUT_CART);
export const setSomethingChanged = () => action(ActionTypes.SOMETHING_CHANGED);
export const checkout = createAsyncAction(
  ActionTypes.CHECKOUT_CART_REQUEST,
  ActionTypes.CHECKOUT_CART_SUCCESS,
  ActionTypes.CHECKOUT_CART_FAILURE,
)<void, {}, Error>();
export const trackOrdersCompletedById = (data: any) => action(ActionTypes.TRACK_ORDERS_COMPLETED, data);
export const createOrderDraft = () => action(ActionTypes.CREATE_ORDER_DRAFT);
export const orderDraft = createAsyncAction(
  ActionTypes.CREATE_ORDER_DRAFT_REQUEST,
  ActionTypes.CREATE_ORDER_DRAFT_SUCCESS,
  ActionTypes.CREATE_ORDER_DRAFT_FAILURE,
)<void, {}, Error>();
export const setOpenConfirmSameDayDeliveryModal = (data) =>
  action(ActionTypes.SET_OPEN_CONFIRM_SAME_DAY_DELIVERY_MODAL, data);

import React, { lazy, Suspense, Fragment } from 'react';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import LoadingIndicator from 'components/LoadingIndicator';
import Button from 'components/Button/Button';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import { Viewport } from 'components/ViewportProvider';
import { Alert } from 'antd';

const StepNumber = styled.div`
  font-size: 14px;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  ${media.sm`
    font-size: 20px;
    width: 36pt;
    height: 36pt;
  `};
`;

const StepLabel = styled.div`
  font-size: 14px;
  position: absolute;
  bottom: -6pt;
  height: 0;
  text-align: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);

  &.text-align-left {
    left: 0;
  }
  &.text-align-right {
    right: 0;
  }
  ${media.sm`
    font-size: 14px;
    &.text-align-left {
      left: auto;
    }
    &.text-align-right {
      right: auto;
    }
  `};
`;

const Step = styled(Button)`
  ${media.sm`
    width: 36pt;
    height: 36pt;
  `};
  height: 20pt;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e8ebf2;
  color: rgba(0, 0, 0, 0.54);
  &.selected {
    ${StepLabel} {
      color: #2e904e;
    }
    background-color: #2e904e;
    color: #ffffff;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40pt;
`;

const StepDivider = styled.div`
  height: 2px;
  width: 85pt;
  margin-top: 9pt;
  background-color: #e8ebf2;
  ${media.sm`
    margin-top: 18pt;
    width: 100pt;
  `};
`;

const AlertContainer = styled.div`
  margin-bottom: 20px;
`;

const CheckoutStep1Page = lazy(() => import('containers/CheckoutStep1Page'));
const CheckoutStep2Page = lazy(() => import('containers/CheckoutStep2Page'));
const CheckoutStep3Page = lazy(() => import('containers/CheckoutStep3Page'));

const Checkout = ({
  match,
  orderDraftToken,
  onGoToCheckoutStep,
  loading,
  orderDisabled,
  orderDisabledMessage,
}: {
  match: any;
  orderDraftToken: string;
  onGoToCheckoutStep: (step: number) => void;
  loading: boolean;
  orderDisabled: boolean;
  orderDisabledMessage: string;
}) => {
  let currentStep = 1;
  if (window.location.href.indexOf('/step/1') > -1) {
    currentStep = 1;
  }
  if (window.location.href.indexOf('/step/2') > -1) {
    currentStep = 2;
  }
  if (window.location.href.indexOf('/step/confirm') > -1) {
    currentStep = 3;
  }
  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isDesktopSize = width >= 575;
        return (
          <Fragment>
            <StepContainer>
              <Step
                type={currentStep === 1 ? 'primary' : ''}
                shape="circle"
                onClick={() => onGoToCheckoutStep(1)}
                className={currentStep === 1 ? 'selected' : ''}
                disabled={orderDisabled}
              >
                {isDesktopSize && <StepNumber>1</StepNumber>}
                <StepLabel className="text-align-left">
                  <FormattedMessage {...messages.cartDetails} />
                </StepLabel>
              </Step>
              <StepDivider />
              <Step
                type={currentStep === 2 ? 'primary' : ''}
                shape="circle"
                onClick={() => onGoToCheckoutStep(2)}
                className={currentStep === 2 ? 'selected' : ''}
                disabled={orderDisabled}
              >
                {isDesktopSize && <StepNumber>2</StepNumber>}
                <StepLabel>
                  <FormattedMessage {...messages.deliveryDetails} />
                </StepLabel>
              </Step>
              <StepDivider />
              <Step
                loading={loading}
                type={currentStep === 3 ? 'primary' : ''}
                shape="circle"
                onClick={() => onGoToCheckoutStep(3)}
                className={currentStep === 3 ? 'selected' : ''}
                disabled={orderDisabled}
              >
                {!loading && isDesktopSize && <StepNumber>3</StepNumber>}
                <StepLabel className="text-align-right">
                  <FormattedMessage {...messages.summary} />
                </StepLabel>
              </Step>
            </StepContainer>
            {orderDisabled && (
              <AlertContainer>
                <Alert message={orderDisabledMessage} type="error" />
              </AlertContainer>
            )}
            <Switch>
              <Route
                path={`${match.path}/step/1`}
                render={(props: any) => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <CheckoutStep1Page {...props} />
                  </Suspense>
                )}
              />
              <Route
                path={`${match.path}/step/2`}
                render={(props: any) => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <CheckoutStep2Page {...props} />
                  </Suspense>
                )}
              />
              <Route
                path={`${match.path}/step/confirm/${orderDraftToken}`}
                render={(props: any) => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <CheckoutStep3Page {...props} />
                  </Suspense>
                )}
              />
              <Redirect to={`${match.path}/step/1`} />
            </Switch>
          </Fragment>
        );
      }}
    </Viewport.Consumer>
  );
};

export default withRouter(Checkout as any) as any;

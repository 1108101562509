import * as Sentry from '@sentry/browser';

import { createMatchSelector, push } from 'connected-react-router';
import {
  orderDisabled,
  recentOrders as recentOrdersMainLayout,
  refreshCart,
  updateItemCart,
} from 'containers/MainLayout/actions';
import { selectCart, selectCountPendingOrder, selectPendingApprovalMode } from 'containers/MainLayout/selectors';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { createOrder, createOrderDraft, getOrder, getOrderDisabled } from 'utils/apollo';
import {
  checkout as checkoutActions,
  createOrderDraft as createOrderDraftAction,
  orderDraft as orderDraftActions,
  setOpenConfirmSameDayDeliveryModal,
  setCheckout,
  setSomethingChanged,
} from './actions';

import { message } from 'antd';
import messages from 'containers/CheckoutPage/messages';
import { getStore } from 'containers/MainLayout/saga';
import moment from 'moment';
import translations from 'translations';
import triggerGA4EcommerceEvent, { EventName } from 'utils/triggerGA4Event';
import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';
import { selectOrderDraftToken, selectCheckout } from './selectors';
import { isEqual } from 'lodash';

function* goToStepFlow(action) {
  const { payload: step } = action;
  const cart = yield select(selectCart());
  const checkout = yield select(selectCheckout());
  let canGoToNextStep = true;
  let isSameDayDelivery = false;
  cart.cartSet.forEach((singleSupplierCart: any) => {
    if (singleSupplierCart.supplier.moq > singleSupplierCart.total) {
      canGoToNextStep = false;
    }
    const deliveryDay = checkout.deliveryDate[singleSupplierCart.supplier.id];
    if (deliveryDay && moment(deliveryDay, 'DD/MM/YYYY').isSame(moment(), 'day')) {
      isSameDayDelivery = true;
    } else {
      isSameDayDelivery = false;
    }
  });

  if (step === 1) {
    yield put(push('/checkout/step/1'));
    return;
  }
  if (canGoToNextStep) {
    if (step === 2) {
      yield put(push('/checkout/step/2'));
      triggerGA4EcommerceEvent(EventName.BEGIN_CHECKOUT, { ...cart.cartSet[0] });
    }
    if (step === 3) {
      if (isSameDayDelivery) {
        yield put(setOpenConfirmSameDayDeliveryModal(true));
        return;
      }
      yield put(createOrderDraftAction());
      triggerGA4EcommerceEvent(EventName.ADD_SHIPPING_INFO, { ...cart.cartSet[0] });
    }
  } else {
    message.error(translations(messages.underMOQMessage));
  }
}

function* createOrderDraftFlow() {
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  yield put(orderDraftActions.request());

  const response = yield call(createOrderDraft, {
    input: {
      storeId: store.id,
    },
  });

  if (!response.errors) {
    yield put(orderDraftActions.success(response));
    yield put(push(`/checkout/step/confirm/${response.draftToken}`));
  } else {
    yield put(orderDraftActions.failure(response.errors));
    response.errors.map((e: any) => message.error(e.message));
  }
}

function* checkoutFlow(action) {
  const orderDisabledResponse = yield call(getOrderDisabled);

  if (!orderDisabledResponse.errors) {
    yield put(orderDisabled.success(orderDisabledResponse));
    if (orderDisabledResponse.orderDisabled) {
      return;
    }

    let draftToken = yield select(selectOrderDraftToken());

    const currentCart = yield select(selectCart());

    yield put(refreshCart());
    yield take(MainActionTypes.FETCH_CART_SUCCESS);

    // Lấy giỏ hàng đã cập nhật từ state
    const cart = yield select(selectCart());

    if (
      cart.notAvailableProducts?.length > 0 ||
      cart.cartSet[0].total < cart.cartSet[0].supplier.moq ||
      !isEqual(cart?.cartSet[0].items, currentCart?.cartSet[0].items) ||
      !isEqual(cart?.cartSet[0].supplier, currentCart?.cartSet[0].supplier)
    ) {
      yield put(setSomethingChanged());
      return;
    }

    const store = yield call(getStore);
    if (!store) {
      return;
    }
    if (!draftToken) {
      const route = yield select(createMatchSelector('/checkout/step/confirm/:slug'));
      draftToken = route.params.slug;
    }
    yield put(checkoutActions.request());
    const response = yield call(createOrder, {
      draftToken: draftToken,
      storeId: store.id,
    });
    if (response.errors) {
      yield put(checkoutActions.failure(response.errors));
      response.errors.map((e: any) => message.error(e.message));
    } else {
      yield put(
        checkoutActions.success({
          status: 'CHECKOUT_SUCCESS',
          orders: response.orders,
        }),
      );
      yield put(updateItemCart.success(response.cart));
      const isPending = yield select(selectPendingApprovalMode());
      if (isPending) {
        const currentCount = yield select(selectCountPendingOrder());
        yield put(recentOrdersMainLayout.success({ countPendingOrders: currentCount + 1 }));
      }
      yield put(
        setCheckout({
          deliveryNotes: [],
          quantity: {},
          totalOfSupplier: {},
          timeSlot: {},
          deliveryDate: {},
          total: 0,
        }),
      );
      window.localStorage.setItem('dayDelivery', '');
      triggerGA4EcommerceEvent(EventName.PURCHASE, {
        ...cart.cartSet[0],
        id: response?.orders[0].id,
      });
    }
  } else {
    yield put(checkoutActions.failure(orderDisabledResponse.errors));
  }
}

function* onTrackOrdersCompleted(action) {
  const response = yield all([...action.payload.map((orderId) => call(getOrder, { id: orderId }))]);

  if (!response.errors) {
    window.localStorage.setItem('checkout_id', '');
  } else {
    Sentry.captureException(response.errors);
  }
}

export default function* checkoutPageSaga() {
  yield all(
    [
      [ActionTypes.CREATE_ORDER_DRAFT, createOrderDraftFlow],
      [ActionTypes.CHECKOUT_CART, checkoutFlow],
      [ActionTypes.GO_TO_STEP, goToStepFlow],
      [ActionTypes.TRACK_ORDERS_COMPLETED, onTrackOrdersCompleted],
    ].map(([actionName, handler]) =>
      fork(function* () {
        while (true) {
          const action = yield take(actionName as any);
          yield call(handler as any, action as any);
        }
      }),
    ),
  );
  yield put(orderDisabled.request());
}

import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal/Modal';
import PromptMessage from 'components/PromptMessage/PromptMessage';
import AcceptedIcon from './Accepted.svg';
import messages from './messages';
import utilMessages from 'utils/messages';
import { FormattedMessage } from 'react-intl';

const ModalMessage = styled.div`
  text-align: center;
  margin-top: 20pt;
  margin-bottom: 18pt;
`;

interface OwnProps {
  isOpen: boolean;
  onSubmit: (isPendingApproval) => void;
  isPendingApproval: boolean;
  orders: any;
  onTrackOrdersCompleted: (ordersId) => void;
}

interface StateProps {}

type Props = StateProps & OwnProps;

export default class OrderCompleteModal extends React.Component<Props> {
  public shouldComponentUpdate(nextProps) {
    if (nextProps.isOpen === this.props.isOpen) {
      return false;
    }
    if (nextProps.isOpen) {
      nextProps.onTrackOrdersCompleted(nextProps.orders);
    }
    return true;
  }

  public render() {
    const { isOpen, onSubmit, orders, isPendingApproval } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        submitLabel={<FormattedMessage {...messages[isPendingApproval ? 'gotoPendingOrders' : 'gotoTodayOrders']} />}
        onSubmit={() => onSubmit(isPendingApproval)}
      >
        <PromptMessage
          icon={AcceptedIcon}
          message={[
            <FormattedMessage {...utilMessages.congrats} key="congrats" />,
            <FormattedMessage
              {...messages[isPendingApproval ? 'yourPendingApprovalOrderSent' : 'yourOrderSent']}
              key="yourOrderSent"
            />,
          ]}
        />
        <ModalMessage>
          {orders &&
            orders.map((order, index) => (
              <p key={index}>
                <FormattedMessage {...utilMessages.orderId} />: #{order.id}
              </p>
            ))}
        </ModalMessage>
      </Modal>
    );
  }
}

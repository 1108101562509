import React from 'react';
import styled from 'styled-components';
import SVGIcon from '../SVGIcon/SVGIcon';

const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PromptIcon = styled(SVGIcon)<any>`
  margin: 0 auto;
  width: 50pt;
  margin-bottom: 6pt;
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: #2e904e;
    }
    circle {
      fill: #2e904e;
    }
  }
`;

const PromptMessage = styled.h5`
  color: #2e904e;
  text-align: center;
  line-height: normal;
  font-size: 26px;
  p {
    margin: 0;
  }
`;

export default function ({ icon, message }: { icon?: string; message: any }) {
  const messages = typeof message === 'string' ? [message] : (message as string[]);
  return (
    <PromptContainer>
      {icon && <PromptIcon renderAsImg={false} src={icon} />}
      <PromptMessage>
        {messages.map((m, index) => (
          <p key={index}>{m}</p>
        ))}
      </PromptMessage>
    </PromptContainer>
  );
}

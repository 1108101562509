/*
 *
 * CheckoutPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  loading: false,
  orderDraftToken: '',
  checkoutStatus: {
    status: 'DRAFT',
  },
  checkout: {
    deliveryNotes: [],
    quantity: {},
    totalOfSupplier: {},
    timeSlot: {},
    deliveryDate: {},
    total: 0,
  },
  somethingChanged: false,
  openConfirmSameDayDeliveryModal: false,
};

function checkoutPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.CREATE_ORDER_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_ORDER_DRAFT_SUCCESS:
      return {
        ...state,
        orderDraftToken: payload.draftToken,
        loading: false,
      };
    case ActionTypes.CREATE_ORDER_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_CHECKOUT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          ...payload,
        },
      };
    case ActionTypes.SET_CHECKOUT_STATUS:
      return {
        ...state,
        checkoutStatus: {
          ...payload,
        },
      };
    case ActionTypes.CHECKOUT_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CHECKOUT_CART_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CHECKOUT_CART_SUCCESS:
      return {
        ...state,
        checkoutStatus: payload,
        loading: false,
      };
    case ActionTypes.SET_OPEN_CONFIRM_SAME_DAY_DELIVERY_MODAL:
      return {
        ...state,
        openConfirmSameDayDeliveryModal: payload,
      };
    case ActionTypes.SOMETHING_CHANGED:
      return {
        ...state,
        somethingChanged: !state.somethingChanged,
      };
    default:
      return state;
  }
}

export default checkoutPageReducer;

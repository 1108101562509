import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the checkoutPage state domain
 */

const selectCheckoutPageDomain = (state: ApplicationRootState) => {
  return state ? state.checkoutPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CheckoutPage
 */

const selectCheckoutPage = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate;
  });

const selectOrderDraftToken = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.orderDraftToken;
  });

const selectCheckout = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.checkout;
  });

const selectLoadingCheckout = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.loading;
  });

const selectCheckoutStatus = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.checkoutStatus;
  });

const selectOpenConfirmSameDayDeliveryModal = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.openConfirmSameDayDeliveryModal;
  });

const selectOpenModalSomthingChanged = () =>
  createSelector(selectCheckoutPageDomain, (substate) => {
    return substate.somethingChanged;
  });

export default selectCheckoutPage;
export {
  selectCheckoutPageDomain,
  selectOrderDraftToken,
  selectCheckout,
  selectCheckoutStatus,
  selectLoadingCheckout,
  selectOpenConfirmSameDayDeliveryModal,
  selectOpenModalSomthingChanged,
};
